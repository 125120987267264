import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  CommentsControllerApi,
  ControllerInitializationOptions,
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import { IFeedItem } from 'api/feed/types';

import type { IRootStore } from 'store/index';
import * as feed from 'store/feed';
import { selectFeedItem } from 'store/feed/selectors';
import { selectIsJoinedGroupMember } from 'store/groups/selectors';
import * as application from 'store/application';

export function CommentsVM(params: ControllerParams, store: IRootStore) {
  let api: CommentsControllerApi;

  return {
    _: {
      comments: {
        init,
        fetch,
        permissionsChanged(groupId: string) {
          api.permissionsChangedExternally({
            contextId: groupId,
            contextType: 'postId',
          });

          setTimeout(() => {
            api.permissionsChangedExternally({
              contextId: groupId,
              contextType: 'postId',
            });
          }, 500);
        },
        dispose() {
          return api?.unmountAllResources();
        },
      },
    },
    comments$: {
      openCommentBox(
        resourceId: string,
        options?: { shouldScroll?: boolean; shouldFocus?: boolean },
      ) {
        api?.affect.tryOpenCommentBox(resourceId, options);
      },
    },
  };

  async function init(options?: Partial<ControllerInitializationOptions>) {
    if (api) {
      return api;
    }

    api = await initializeCommentsController(params.controllerConfig, {
      _persistDraft: true,
      shouldAutoBindStateToSetProps: true,
      appDefinitionId: params.controllerConfig.appParams.appDefinitionId,
      httpClient: params.flowAPI.httpClient,
      actionPreconditions: {
        isPreconditionRequiredForAction(userAction, { srcResourceId }) {
          const feedItem = selectFeedItem(store.getState(), srcResourceId);
          const groupId = feedItem?.applicationContext?.contextId as string;

          return !selectIsJoinedGroupMember(store.getState(), groupId);
        },
        async preconditionCallback(userAction, { srcResourceId }) {
          const resource = selectFeedItem(store.getState(), srcResourceId);

          store.dispatch(
            application.actions.showDialog({
              dialog: 'joinGroup',
              params: {
                groupId: resource?.applicationContext?.contextId,
              },
            }),
          );
        },
      },
      ...options,
    });

    api.watch.pagination.onChange(handleCommentsChange);

    return api;
  }

  async function fetch(resources?: IFeedItem[]): Promise<void> {
    if (!api || !resources?.length) {
      return;
    }

    return api.bulkFetchComments(
      resources.filter(canViewComments).map((resource) => ({
        resourceId: resource.feedItemId as string,
        ctxFields: {
          contextType: 'postId',
          contextId: resource.applicationContext?.contextId as string,
        },
        pagination: {
          replyShowMoreLimit: 10,
          initialPage: {
            commentLimit: 1,
            replyLimit: 0,
          },
          pagination: {
            commentLimit: 20,
            replyLimit: 0,
          },
        },
      })),
    );
  }

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }

  function canViewComments(item: IFeedItem) {
    return item.permissions?.canViewFullPost;
  }
}

export type ICommentsVM = ReturnType<typeof CommentsVM>;
