import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { doNotTransformSettingsKey } from '../helpers';

export const settings = {
  newPostBoxLabel: createSettingsParam('newPostBoxLabel', {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('groups-web.discussion.create-post.placeholder'),
  }),
};

export const styles = {
  postsTextColor: createStylesParam('postsTextColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  postsTextFont: createStylesParam('postsTextFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),
  mobilePostsTextFontSize: createStylesParam('mobilePostsTextFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),

  memberNameColor: createStylesParam('memberNameColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  memberNameFont: createStylesParam('memberNameFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  postingDateColor: createStylesParam('postingDateColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  postingDateFont: createStylesParam('postingDateFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),

  commentsReactionsCounterColor: createStylesParam(
    'commentsReactionsCounterColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    },
  ),
  commentsReactionsCounterFont: createStylesParam(
    'commentsReactionsCounterFont',
    {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', { size: 14 }),
    },
  ),
};
