import { configureStore } from '@reduxjs/toolkit';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import * as application from './application';
import * as groups from './groups';
import * as media from './media';
import * as files from './files';
import * as feed from './feed';
import * as topics from './topics';
import * as events from './events';
import * as members from './members';
import * as groupRequests from './group-requests';

export function createStore(flowApi: ControllerFlowAPI, preloadedState?: any) {
  return configureStore({
    preloadedState,
    devTools: false,
    reducer: {
      application: application.reducer,
      groups: groups.reducer,
      media: media.reducer,
      files: files.reducer,
      feed: feed.reducer,
      topics: topics.reducer,
      events: events.reducer,
      members: members.reducer,
      groupRequests: groupRequests.reducer,
    },
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: flowApi,
        },
      });
    },
  });
}

export type IRootStore = ReturnType<typeof createStore>;
export type IRootState = ReturnType<IRootStore['getState']>;
export type IRootStoreDispatch = IRootStore['dispatch'];
