import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { JoinRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import type { GroupSettings } from '@wix/ambassador-social-groups-v2-group/types';
import type { MembershipQuestion } from '@wix/ambassador-social-groups-v2-membership-question/types';
import type {
  NotificationChannel,
  NotificationSettings,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';

import type { UIRouter } from '@wix/tpa-router';

import * as groups from 'store/groups';
import * as application from 'store/application';
import { GroupApp, IUpdateGroupInfoParams } from 'store/groups/types';
import { IRootStore } from 'store/index';

import { type ICommentsVM } from './comments.vm';

export function GroupVM(
  params: ControllerParams,
  commentsVM: ICommentsVM,
  store: IRootStore,
  router: UIRouter,
) {
  return {
    group$: {
      join,
      leave,
      remove,
      cancelRequest,
      fetch,
      fetchRules,
      fetchActivity,
      fetchQuestions,
      updateQuestions,
      updateGroupInfo,
      updateGroupSettings,
      configureApps,
      resetActivityCounter,
      fetchNotificationsSettings,
      updateNotificationsSettings,
    },
  };

  function resetActivityCounter(groupId: string) {
    return store.dispatch(groups.thunks.resetActivityCounter(groupId));
  }

  function fetchActivity(groupId: string) {
    return store.dispatch(groups.thunks.fetchActivity(groupId));
  }

  function fetchRules(groupId: string) {
    return store.dispatch(groups.thunks.fetchRules(groupId));
  }

  async function join(params: JoinRequest & { redirect?: boolean }) {
    try {
      const group = await store.dispatch(groups.thunks.join(params)).unwrap();

      commentsVM._.comments.permissionsChanged(params.groupId as string);

      if (params.redirect) {
        router.stateService.go('group', { slug: group.slug });
      }
    } catch {
      // probably some restrictions
    }
  }

  async function fetchQuestions(groupId: string) {
    store.dispatch(groups.thunks.fetchQuestions(groupId));
  }

  async function updateQuestions(
    groupId: string,
    questions: MembershipQuestion[],
  ) {
    store.dispatch(
      groups.thunks.updateQuestions({
        groupId,
        questions,
      }),
    );
  }

  async function leave(groupId: string) {
    await store.dispatch(groups.thunks.leave(groupId));
    commentsVM._.comments.permissionsChanged(groupId);
    store.dispatch(application.actions.closeDialog('leaveGroup'));
  }

  async function remove(groupId: string) {
    await store.dispatch(groups.thunks.remove(groupId));
    router.stateService.go('groups.list');
  }

  async function cancelRequest(groupId: string) {
    await store.dispatch(groups.thunks.cancelRequest(groupId));

    store.dispatch(application.actions.closeDialog('cancelJoinRequest'));
  }

  function fetch(groupIdOrSlug: string, autoInviteId?: string) {
    return store.dispatch(
      groups.thunks.fetchGroup({
        groupIdOrSlug,
        autoInviteId,
      }),
    );
  }

  function updateGroupInfo(
    groupId: string,
    groupChanges: Omit<IUpdateGroupInfoParams, 'groupId'>,
  ) {
    return store.dispatch(
      groups.thunks.updateGroupInfo({ groupId, ...groupChanges }),
    );
  }

  function updateGroupSettings(groupId: string, settings: GroupSettings) {
    return store.dispatch(
      groups.thunks.updateGroupSettings({ groupId, settings }),
    );
  }

  function configureApps(groupId: string, apps: GroupApp[]) {
    return store.dispatch(groups.thunks.updateGroupApps({ groupId, apps }));
  }

  function fetchNotificationsSettings(
    groupId: string,
    channel: NotificationChannel,
  ) {
    return store.dispatch(
      groups.thunks.fetchNotificationSettings({ groupId, channel }),
    );
  }

  function updateNotificationsSettings(
    groupId: string,
    channel: NotificationChannel,
    settings: NotificationSettings[],
  ) {
    return store.dispatch(
      groups.thunks.updateNotificationSettings({ groupId, channel, settings }),
    );
  }
}

export type IGroupVM = ReturnType<typeof GroupVM>;
