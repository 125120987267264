import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { migrate } from '../settings/migration';

/**
 * Viewer only
 * will migrate styleParams
 */
export function migrateStyles(params: ControllerParams) {
  const { isViewer } = params.flowAPI.environment;
  const { setProps, config } = params.flowAPI.controllerConfig;

  if (isViewer) {
    setProps(
      params.dangerousStylesOverride(
        migrate(config.style.styleParams, config.publicData),
      ),
    );
  }
}
