import { IStyleParam, StyleParamType } from '@wix/tpa-settings';

export type IGroupsListWidgetStylesParams = {
  showGroupType: IStyleParam<StyleParamType.Boolean>;
  showActivity: IStyleParam<StyleParamType.Boolean>;
  showButton: IStyleParam<StyleParamType.Boolean>;
};

// noinspection JSUnusedGlobalSymbols
export const groupsListWidgetStylesParams: IGroupsListWidgetStylesParams = {
  /**
   * Design styles
   */
  showActivity: {
    key: 'showActivity',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showGroupType: {
    key: 'showGroupType',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showButton: {
    key: 'showButton',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
};
