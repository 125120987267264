import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

import { transformSettingsKey } from '../helpers';

export const settings = {
  // @deprecated use styleParam instead
  showShareButton: createSettingsParam('showShareButton', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
};

export const styles = {
  postIconsColor: createStylesParam('postIconsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  showShareButton: createStylesParam('showShareButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
