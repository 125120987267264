import { ControllerParams } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from 'api/groups/types';
import type { IViewModel } from 'controller/types';

import { GroupsRequestSort, LayoutType } from 'settings/consts';
import { GroupsSelectorType } from 'settings/groups-list-widget';

import settingsParams from '../settingsParams';

type Resolvables = {
  persisted: boolean;
};

export function resolve(vm: IViewModel, params: ControllerParams) {
  const { settings, experiments } = params.flowAPI;

  return async function (resolvables?: Resolvables) {
    if (resolvables?.persisted) {
      return resolvables;
    }

    await Promise.all([
      vm.application$.fetchUserProfile(),
      vm.groups$.query({
        limit: 6,
        namespace: EGroupPartition.ALL,
        partition: EGroupPartition.ALL,
        sort: settings.get(settingsParams.sortBy) as GroupsRequestSort,
        groupIds: getGroupIdsToShow(),
      }),
    ]);

    return { persisted: true };
  };

  function getGroupIdsToShow() {
    switch (settings.get(settingsParams.groupsSelectorType)) {
      case GroupsSelectorType.ALL:
        return undefined;
      case GroupsSelectorType.CUSTOM:
        try {
          return JSON.parse(settings.get(settingsParams.groupIdsToShow));
        } catch (e: any) {
          return [];
        }
    }
  }
}
