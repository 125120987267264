import * as media from 'api/media';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'media:fetch',
  async function (groupId: string, thunkAPI) {
    const response = await thunkAPI.extra.httpClient.request(
      media.fetch(groupId),
    );

    return response.data;
  },
);
