import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { Alignment, FontTheme } from '../consts';

export const styles = {
  // use in list/grid layout
  feedVerticalSpacing: createStylesParam('feedVerticalSpacing', {
    getDefaultValue: () => 24,
    type: StyleParamType.Number,
  }),
  feedTitleFont: createStylesParam('feedTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_1, {
      size: 20,
      htmlTag: 'h1',
    }),
  }),
  feedTitleColor: createStylesParam('feedTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  showFeedTitle: createStylesParam('showFeedTitle', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),
  showCreatePost: createStylesParam('showCreatePost', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

export const settings = {
  feedTitle: createSettingsParam('feedTitle', {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedTitle.default'),
  }),

  // @deprecated use styleParam instead
  showFeedTitle: createSettingsParam('showFeedTitle', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),

  /**
   * ❗️the option couldn't be migrated to `styleParams`
   * because `styleParams` aren't accessible in `CreateControllerFn`
   *
   * Depending on the value we decide to fetch feed and comments or not
   *
   * Always fetch feed (not relying on the option) will cost approximately 900-1300ms,
   * and on `pageReady` after SSR +800ms for fetching comments
   *
   * Looks like `showFeed` should be a "global" option and doesn't rely on css breakpoints.
   * using in List/Grid layout
   * TODO: migrate to cssVars using: styles.set(designKeys.showFeed, value, { isGlobal: true });
   */
  showFeed: createSettingsParam('showFeed', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  }),
  // @deprecated use styleParam instead
  showCreatePost: createSettingsParam('showCreatePost', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // TODO: migrate to styleParams
  feedAlignment: createSettingsParam('feedAlignment', {
    type: SettingsParamType.String,
    getDefaultValue: () => Alignment.center,
  }),
};
