import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  const { isSSR } = params.flowAPI.environment;

  return {
    abstract: true,
    url: '/discussion',
    name: 'group.discussion',
    data: { sectionId: 'group' },
    resolve: [
      {
        token: 'feedAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FEED_APP,
          });
        },
      },
      {
        token: 'commentsApi',
        async resolveFn() {
          if (!isSSR) {
            await vm._.comments.init();
          }
        },
      },
      {
        token: 'ppApi',
        async resolveFn() {
          await vm._.pricingPlans.init();
        },
      },
      {
        token: 'membersAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'eventsAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'topics',
        deps: ['groupId', 'feedAvailable'],
        resolveFn(groupId: string, isFeedAvailable: boolean) {
          if (isFeedAvailable) {
            return vm.topics$.fetch(groupId);
          }
        },
      },
      {
        token: 'events',
        deps: ['groupId', 'eventsAvailable'],
        resolveFn(groupId: string, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(groupId);
          }
        },
      },
      {
        token: 'members',
        deps: ['groupId', 'membersAvailable'],
        resolveFn(groupId: string, isMembersAvailable: boolean) {
          if (isMembersAvailable) {
            return vm.members$.fetch(groupId, 5);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
