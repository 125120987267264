import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { doNotTransformSettingsKey, transformSettingsKey } from '../helpers';

import {
  Alignment,
  COMPONENT,
  DEFAULT_GRID_CARD_SPACING,
  FontTheme,
  GroupsRequestSort,
  ImageRatio,
  LayoutType,
} from '../consts';

const groupListLayout = createSettingsParam('groupListLayout', {
  getDefaultValue: () => {
    return getDefaultGroupListLayout();
  },
  dangerousKeyTransformationOverride: transformSettingsKey,
  type: SettingsParamType.String,
});

export const settings = {
  groupListTitle: createSettingsParam('groupListTitle', {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('groups-web.settings.TextTab.groups.default'),
  }),
  groupMembersLabel: createSettingsParam('groupMembersLabel', {
    getDefaultValue: () => 'Members',
    type: SettingsParamType.Text,
  }),
  // @deprecated use styleParam instead
  showHeaderTitle: createSettingsParam('showHeaderTitle', {
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
    type: SettingsParamType.Boolean,
  }),
  // @deprecated use styleParam instead
  showSearchSorting: createSettingsParam('showSearchSorting', {
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
    type: SettingsParamType.Boolean,
  }),
  // @deprecated use styleParam instead
  showCreateGroupButton: createSettingsParam('showCreateGroupButton', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
  }),
  // @deprecated use styleParam instead
  showGroupsToJoin: createSettingsParam('showGroupsToJoin', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
  }),
  groupListLayout,
  // TODO: migrate to styleParams
  headerAlignment: createSettingsParam('headerAlignment', {
    getDefaultValue: () => Alignment.left,
    type: SettingsParamType.String,
  }),
  // TODO: migrate to styleParams
  sideBarAlignment: createSettingsParam('sideBarAlignment', {
    getDefaultValue: () => Alignment.right,
    type: SettingsParamType.String,
  }),
  // TODO: migrate to styleParams
  imageRatio: createSettingsParam('imageRatio', {
    getDefaultValue: ({ getSettingParamValue }) => {
      // https://github.com/wix-private/tpa-settings#10-dependent-default-values
      // Important note, that getSettingParamValue(groupsSettingsParams.groupListLayout) will not get the groupListLayout default value.
      const layout =
        getSettingParamValue(groupListLayout) || getDefaultGroupListLayout();
      if (layout === LayoutType.listWidget) {
        return ImageRatio.square;
      }

      return ImageRatio.rectangle;
    },
    dangerousKeyTransformationOverride: transformSettingsKey,
    type: SettingsParamType.String,
  }),
  // TODO: migrate to styleParams
  gridCardSpacing: createSettingsParam('gridCardSpacing', {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    dangerousKeyTransformationOverride: transformSettingsKey,
    type: SettingsParamType.Number,
  }),
  sortBy: createSettingsParam('sortBy', {
    getDefaultValue: () => GroupsRequestSort.RECENT_ACTIVITY,
    type: SettingsParamType.String,
  }),
};

export const styles = {
  groupCardGridSpacing: createStylesParam('groupCardGridSpacing', {
    getDefaultValue: () => 12,
    inheritDesktop: false,
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
  listWidgetSpacing: createStylesParam('listWidgetSpacing', {
    getDefaultValue: () => 0,
    inheritDesktop: false,
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
  sideBarHorizontalSpacing: createStylesParam('sideBarHorizontalSpacing', {
    getDefaultValue: () => 40,
    type: StyleParamType.Number,
  }),
  sideBarVerticalSpacing: createStylesParam('sideBarVerticalSpacing', {
    getDefaultValue: ({ isMobile }) => (isMobile ? 12 : 24),
    type: StyleParamType.Number,
  }),
  sideBarProportion: createStylesParam('sideBarProportion', {
    getDefaultValue: () => 30,
    type: StyleParamType.Number,
  }),

  listTitleColor: createStylesParam('listTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  listTitleFont: createStylesParam('listTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_1, {
      size: 24,
      htmlTag: 'h1',
    }),
  }),
  mobileListTitleFontSize: createStylesParam('mobileListTitleFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),
  searchSortingColor: createStylesParam('searchSortingColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  sidebarTitleFont: createStylesParam('sidebarTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_2, { size: 16 }),
  }),
  sidebarTitleColor: createStylesParam('sidebarTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  showHeaderTitle: createStylesParam('showHeaderTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showSearchSorting: createStylesParam('showSearchSorting', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // use in sidebar layout
  showCreateGroupButton: createStylesParam('showCreateGroupButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showGroupsToJoin: createStylesParam('showGroupsToJoin', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showFeedTitle: createStylesParam('showFeedTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // using in List/Grid layout
  showFeed: createStylesParam('showFeed', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
};

function getDefaultGroupListLayout() {
  switch (COMPONENT.name) {
    case 'GroupListWidget':
      return LayoutType.listWidget;
    case 'SideBySideWidget':
      return LayoutType.sideBySide;
    default:
      return LayoutType.list;
  }
}
