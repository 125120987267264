import type { RawParams, Transition } from '@wix/tpa-router';

import type { IGroup } from 'api/groups/types';
import type { IApplicationUser } from 'store/application/types';
import {
  selectCanBrowse,
  selectGroupBySlug,
  selectHasAdminRole,
  selectIsJoinedGroupMember,
} from 'store/selectors';

import type { StateDeclarationFn } from 'controller/types';

import { INVITE_PAID_PLANS } from '../../../constants';

export default (function (vm, store, params) {
  const { isSSR } = params.flowAPI.environment;

  return {
    name: 'group',
    parent: 'social-groups',
    url: '/:slug?autoInviteId&invite&appSectionParams',
    params: {
      appSectionParams: {
        dynamic: true,
        type: 'json',
      },
      invite: {
        type: 'bool',
        dynamic: true,
      },
    },
    data: { sectionId: 'group' },
    async onEnter(transition: Transition) {
      const params = transition.params();
      const injector = transition.injector();

      if (isSSR) {
        return;
      }

      const isJoined = await injector.getAsync<boolean>('isJoined');
      const groupId = await injector.getAsync<string>('groupId');

      if (isJoined) {
        vm.group$.resetActivityCounter(groupId);
      }

      if (groupId && !isJoined && shouldTriggerJoin(params)) {
        void vm.group$.join({
          groupId,
          autoInviteId: params.autoInviteId,
        });
      }
    },
    async redirectTo(transition) {
      const injector = transition.injector();

      const groupId = await injector.getAsync<string>('groupId');

      if (selectCanBrowse(store.getState(), groupId)) {
        return 'group.discussion.feed';
      }

      return 'group.about';
    },
    resolve: [
      {
        token: 'user',
        policy: { async: 'WAIT' },
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'group',
        deps: ['$stateParams', '$transition$'],
        async resolveFn(params: RawParams, transition: Transition) {
          const { custom } = transition.options();

          if (custom?.retain) {
            return selectGroupBySlug(store.getState(), params.slug);
          }

          const data = await vm.group$
            .fetch(params.slug, params.autoInviteId)
            .unwrap();

          return data.group;
        },
      },
      {
        token: 'groupId',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return group.id;
        },
      },
      {
        token: 'isAdmin',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectHasAdminRole(store.getState(), groupId);
        },
      },
      {
        token: 'isJoined',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsJoinedGroupMember(store.getState(), groupId);
        },
      },
    ],
  };

  function shouldTriggerJoin(params: RawParams) {
    try {
      const inviteFromPlans =
        params.appSectionParams?.invite === INVITE_PAID_PLANS;

      return params.invite || params.autoInviteId || inviteFromPlans;
    } catch (e) {
      return false;
    }
  }
} as StateDeclarationFn);
